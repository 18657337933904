import { useNotifications } from '@toolpad/core/useNotifications';

export function notify(
  notifications: ReturnType<typeof useNotifications>,
  message: string,
  severity: 'info' | 'error' = 'info',
  autoHideDuration: number = 3000,
) {
  notifications.show(message, {
    severity,
    autoHideDuration,
  });
}
