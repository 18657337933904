// Make sure react-hot-loader is required before react and react-dom:
import { hot } from 'react-hot-loader/root';
import React, { Suspense } from 'react';
import { Provider } from 'react-redux';
import { Route, BrowserRouter, Routes } from 'react-router-dom';
import { QueryClientProvider, QueryClient } from 'react-query';
import { MatomoProvider, useMatomo } from '@datapunt/matomo-tracker-react';
import { ErrorBoundary as AppSignalErrorBoundary } from '@appsignal/react';
import ModalProvider from 'mui-modal-provider';
import { NotificationsProvider } from '@toolpad/core/useNotifications';

import { store } from './Redux';
import StartupActions from './Redux/StartupRedux';
import ConnectedIntlProvider from './Components/ConnectedIntlProvider';
import Layout from './Layout/Layout';
import EngageThemeProvider from './Layout/EngageThemeProvider';
import DelayedLoader from './Components/DelayedLoader';
import SpaceRoutes from './Pages/Entities/SpaceRoutes';
import { isAccessingViaCustomDomain } from './Utils';
import ErrorBoundary from './Components/ErrorBoundary';
import AnalyticsService from './Services/AnalyticsService';
import appsignal from './Services/ErrorReportingService';
import { CookieSettingsProvider } from './Utils/CookieSettingsContext';
import ScrollToTop from './Utils/ScrollToTop';
import ExternalHistoryRouter from './Components/ExternalHistoryRouter';

const NotFoundPage = React.lazy(() => import('./Pages/ErrorPage'));
const HomeEngage = React.lazy(() => import('./Pages/Home'));

function AppRoutes() {
  const accessFromFluicityDomain = !isAccessingViaCustomDomain();
  const { enableLinkTracking } = useMatomo();

  enableLinkTracking();

  return (
    <Routes>
      <Route path="/" element={accessFromFluicityDomain ? <HomeEngage /> : <SpaceRoutes />} />
      <Route path="home" element={<HomeEngage />} />
      <Route path=":spaceSlug/*" element={<SpaceRoutes />} />
      <Route element={<NotFoundPage />} />
    </Routes>
  );
}

const App: React.FC = () => {
  const queryClient = new QueryClient();

  React.useEffect(() => {
    store.dispatch(StartupActions.startup(store));
  }, []);

  return (
    <AppSignalErrorBoundary instance={appsignal}>
      <MatomoProvider value={AnalyticsService.matomoInstance}>
        <BrowserRouter>
          <Provider store={store}>
            <ExternalHistoryRouter>
              <ConnectedIntlProvider>
                <QueryClientProvider client={queryClient}>
                  <CookieSettingsProvider>
                    <EngageThemeProvider>
                      <NotificationsProvider>
                        <ModalProvider>
                          <Layout>
                            <ErrorBoundary>
                              <Suspense fallback={<DelayedLoader />}>
                                <ScrollToTop />
                                <AppRoutes />
                              </Suspense>
                            </ErrorBoundary>
                          </Layout>
                        </ModalProvider>
                      </NotificationsProvider>
                    </EngageThemeProvider>
                  </CookieSettingsProvider>
                </QueryClientProvider>
              </ConnectedIntlProvider>
            </ExternalHistoryRouter>
          </Provider>
        </BrowserRouter>
      </MatomoProvider>
    </AppSignalErrorBoundary>
  );
};

export default hot(App);
